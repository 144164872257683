import { ListenerMiddlewareInstance } from "@reduxjs/toolkit";

import { BRAND_NAME, PRODUCT_CATEGORY_IDS } from "@chef/constants";
import { createAndSendEvent } from "@chef/events";
import { isDeepEquals } from "@chef/utils/equal";

import {
  deriveProductPropertiesFromState,
  deriveVariationPropertiesFromState,
  RootState,
} from "..";
import {
  selectSignupMealbox,
  selectSignupWeekAndYear,
  setSignupMealbox,
} from "../features";
import { api } from "../graphql/api";
import { ProductsByCategoriesQuery } from "../graphql/generated";
import { getPriceOfProductByVariation } from "../helpers";

// TODO not sure if this is the best filename
export default (listener: ListenerMiddlewareInstance) =>
  listener.startListening({
    actionCreator: setSignupMealbox,
    effect: async (action, { dispatch, getOriginalState }) => {
      const state = getOriginalState() as RootState;

      if (
        isDeepEquals(state.signupRtk.mealbox, action.payload) ||
        !action.payload.userInitiated
      ) {
        return;
      }

      const oldMealbox = selectSignupMealbox(state);
      const newMealbox = action.payload;
      const { week, year } = selectSignupWeekAndYear(state);

      const productsByCategoriesResponse = await dispatch(
        api.endpoints.productsByCategories.initiate({
          categoryIds: [PRODUCT_CATEGORY_IDS.MEALBOX_LOGGED_OUT],
          week,
          year,
        }),
      ).unwrap();

      const discountCoupon = state.signupRtk.coupon;

      track({
        eventName: "Product Added",
        couponCode: discountCoupon?.couponCode || "",
        productId: newMealbox.productId,
        variationId: newMealbox.variationId,
        productsByCategories: productsByCategoriesResponse,
      });

      track({
        eventName: "Product Removed",
        couponCode: discountCoupon?.couponCode || "",

        productId: oldMealbox.productId,
        variationId: oldMealbox.variationId,
        productsByCategories: productsByCategoriesResponse,
      });
    },
  });

const track = ({
  eventName,
  productId,
  variationId,
  productsByCategories,
  couponCode,
}: {
  eventName: "Product Added" | "Product Removed";
  productsByCategories: ProductsByCategoriesQuery;
  productId: string;
  variationId: string;
  couponCode: string;
}) => {
  const [productCategory, productName, productTypeId] =
    deriveProductPropertiesFromState(
      ["productTypeName", "productTypeId"],
      productId,
      productsByCategories,
    );

  const selectedProduct = productsByCategories.productsByCategories
    .flatMap((pbc) => pbc.products)
    .find((product) => product.productId === productId);

  const selectedVariation = selectedProduct?.variations.find(
    (variation) => variation.variationId === variationId,
  );

  const variationPrice =
    (selectedVariation && getPriceOfProductByVariation(selectedVariation)) || 0;

  if (!selectedVariation) {
    console.error("Variation not found", {
      productId,
      variationId,
      productsByCategories,
    });
  }

  const [variant, sku] = deriveVariationPropertiesFromState(
    ["name", "sku"],
    productId,
    variationId,
    productsByCategories,
  );

  createAndSendEvent(eventName, {
    affiliation: "Frontend process",
    brand: BRAND_NAME,
    cart_id: "signup-cart",
    category: productCategory,
    category_id: productTypeId,
    coupon: couponCode,
    name: productName,
    price: variationPrice,
    product_id: productId,
    quantity: 1,
    sku,
    variant,
    variant_id: variationId,
  });
};
